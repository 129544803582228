import * as React from "react";
import { Container } from "react-bootstrap";
import PageHeader from "../components/PageHeader";
import PageLayout from "../components/PageLayout";

const TermsAndConditionsPage = () => {
  return (
    <PageLayout pageTitle="Terms & Conditions - matthewgoslett.com">
      <Container className="mt-4">
        <PageHeader header="Terms &amp; Conditions" />
        <h3>1. Terms</h3>
        <p>
          By accessing the website at{" "}
          <a href="https://matthewgoslett.com">https://matthewgoslett.com</a>,
          you are agreeing to be bound by these terms of service, all applicable
          laws and regulations, and agree that you are responsible for
          compliance with any applicable local laws. If you do not agree with
          any of these terms, you are prohibited from using or accessing this
          site. The materials contained in this website are protected by
          applicable copyright and trademark law.
        </p>
        <h3>2. Use License</h3>
        <ol type="a">
          <li>
            Permission is granted to temporarily download one copy of the
            materials (information or software) on Matthew Goslett's website for
            personal, non-commercial transitory viewing only. This is the grant
            of a license, not a transfer of title, and under this license you
            may not:
            <ol type="i">
              <li>modify or copy the materials;</li>
              <li>
                use the materials for any commercial purpose, or for any public
                display (commercial or non-commercial);
              </li>
              <li>
                attempt to decompile or reverse engineer any software contained
                on Matthew Goslett's website;
              </li>
              <li>
                remove any copyright or other proprietary notations from the
                materials; or
              </li>
              <li>
                transfer the materials to another person or "mirror" the
                materials on any other server.
              </li>
            </ol>
          </li>
          <li>
            This license shall automatically terminate if you violate any of
            these restrictions and may be terminated by Matthew Goslett at any
            time. Upon terminating your viewing of these materials or upon the
            termination of this license, you must destroy any downloaded
            materials in your possession whether in electronic or printed
            format.
          </li>
        </ol>
        <h3>3. Disclaimer</h3>
        <ol type="a">
          <li>
            The materials on Matthew Goslett's website are provided on an 'as
            is' basis. Matthew Goslett makes no warranties, expressed or
            implied, and hereby disclaims and negates all other warranties
            including, without limitation, implied warranties or conditions of
            merchantability, fitness for a particular purpose, or
            non-infringement of intellectual property or other violation of
            rights.
          </li>
          <li>
            Further, Matthew Goslett does not warrant or make any
            representations concerning the accuracy, likely results, or
            reliability of the use of the materials on his website or otherwise
            relating to such materials or on any sites linked to this site.
          </li>
        </ol>
        <h3>4. Limitations</h3>
        <p>
          In no event shall Matthew Goslett or his suppliers be liable for any
          damages (including, without limitation, damages for loss of data or
          profit, or due to business interruption) arising out of the use or
          inability to use the materials on Matthew Goslett's website, even if
          Matthew Goslett or an authorized representative of Matthew Goslett has
          been notified orally or in writing of the possibility of such damage.
          Because some jurisdictions do not allow limitations on implied
          warranties, or limitations of liability for consequential or
          incidental damages, these limitations may not apply to you.
        </p>
        <h3>5. Accuracy of materials</h3>
        <p>
          The materials appearing on Matthew Goslett's website could include
          technical, typographical, or photographic errors. Matthew Goslett does
          not warrant that any of the materials on its website are accurate,
          complete or current. Matthew Goslett may make changes to the materials
          contained on its website at any time without notice. However Matthew
          Goslett does not make any commitment to update the materials.
        </p>
        <h3>6. Links</h3>
        <p>
          Matthew Goslett has not reviewed all of the sites linked to its
          website and is not responsible for the contents of any such linked
          site. The inclusion of any link does not imply endorsement by Matthew
          Goslett of the site. Use of any such linked website is at the user's
          own risk.
        </p>
        <h3>7. Modifications</h3>
        <p>
          Matthew Goslett may revise these terms of service for its website at
          any time without notice. By using this website you are agreeing to be
          bound by the then current version of these terms of service.
        </p>
        <h3>8. Governing Law</h3>
        <p>
          These terms and conditions are governed by and construed in accordance
          with the laws of South Africa and you irrevocably submit to the
          exclusive jurisdiction of the courts in that State or location.
        </p>
        <h2>Privacy Policy</h2>
        <p>
          Your privacy is important to me. It is my policy to respect your
          privacy regarding any information I may collect from you across my
          website,{" "}
          <a href="https://matthewgoslett.com">https://matthewgoslett.com</a>,
          and other sites I own and operate.
        </p>
        <p>
          I only ask for personal information when I truly need it to provide a
          service to you. I collect it by fair and lawful means, with your
          knowledge and consent. I also let you know why I'm collecting it and
          how it will be used.
        </p>
        <p>
          I only retain collected information for as long as necessary to
          provide you with your requested service. What data I store, I'll
          protect within commercially acceptable means to prevent loss and
          theft, as well as unauthorised access, disclosure, copying, use or
          modification.
        </p>
        <p>
          I don’t share any personally identifying information publicly or with
          third-parties, except when required to by law.
        </p>
        <p>
          My website may link to external sites that are not operated by me.
          Please be aware that I have no control over the content and practices
          of these sites, and cannot accept responsibility or liability for
          their respective privacy policies.
        </p>
        <p>
          You are free to refuse our request for your personal information, with
          the understanding that I may be unable to provide you with some of
          your desired services.
        </p>
        <p>
          Your continued use of our website will be regarded as acceptance of my
          practices around privacy and personal information. If you have any
          questions about how I handle user data and personal information, feel
          free to contact me.
        </p>
        <p>This policy is effective as of 27 May 2018.</p>
      </Container>
    </PageLayout>
  );
};

export default TermsAndConditionsPage;
